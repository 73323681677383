import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonService, SharedserviceService } from 'src/app/services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $: any;


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  user:any;
  user_type:any;
  name:any;
  id:any;
  showlogin:boolean=true;
  parseuser:any;
  getData: any;
  formEmail: FormGroup;
  submitted: boolean = false;
  date :any;
  retDate:any;
  userData:any;
  constructor(private router: Router,public commonService:CommonService,public sharedService: SharedserviceService,private toastr: ToastrManager, public ngxloader: NgxUiLoaderService, public cds: CommonService,private fb: FormBuilder) {
    this.date= new Date();
    this.retDate= new Date();
  }

  ngOnInit() { 
   

    this.contactDetail();
    if(this.user){
      this.id = this.user.id;
      this.user_type = this.user.role_id;
      
//console.log("usertype",this.user_type);
      this.showlogin=false;
      this.name = this.user.name;
    }
    
    this.formEmail = this.fb.group({
      name: ['',Validators.required],
      email: ['',Validators.required],
      subject: ['',Validators.required],
      message: ['',Validators.required],
      mobile_no: ['',Validators.required],
    
      send_to_email:['']
    });
    
  }

  // checkValue(val){
  //   const stringified = JSON.stringify(val.value);
  //   const dob = stringified;
  //   console.log("check dob value;;;;;;;;;",dob);
  //   this.retDate = dob;
  //   console.log("check departure valu:::::::",this.retDate);
  //   // this.retDate =  new Date(val);
  // }
  get enq() { return this.formEmail.controls; }
  
  contactDetail() {

    const self = this;
    self.sharedService.get('v1/users/get_website_owner_data').subscribe((res: any) => {
      if (res.replyCode == "success") {
        this.getData = res.data;
        console.log("::::::::::::::",this.getData);
        sessionStorage.setItem("ownerData", JSON.stringify(this.getData));
      } else {
        
      }
    });
  }

  profile(id){
    this.router.navigateByUrl("/dashboard/viewprofile");
   
  }
  logout() {
    this.showlogin=true;
    this.commonService.onLogout();
  }

  closeENQ() {
    $('#Enq_us').modal('hide');
  }
  ENQ() {
        $('#Enq_us').modal('show');
  }

  // setDefaultDataInEmail(){
  //   // console.log("event check user value",event);
  //   if(this.userData){
  //   this.formEmail.patchValue({
  //     name: this.userData.name,
  //     email: this.userData.email,
  //     mobile_no: this.userData.mobile_no,
  //   })
  // }
  // }
  
  enquiryForm(){
    this.submitted = true;
    if (this.formEmail.invalid) { 
      return;
    }else{
    var requestData ={
      "name":this.formEmail.value.name,
      "email":this.formEmail.value.email,
      "subject": this.formEmail.value.subject,
      "message": this.formEmail.value.message,
      "company_name": "test",
      "mobile_no": this.formEmail.value.mobile_no.toString(),
     
      "send_to_email":this.getData.email
    }
    this.sharedService.post('v1/pages/contact_us',requestData).subscribe((res: any) => {
      if (res.replyCode == "success") {
        $('#Enq_us').modal('hide');
        this.submitted = false;
        this.toastr.successToastr(res.replyMsg, 'Success');
      } else {
        this.toastr.errorToastr(res.message, 'Error');
      }
    },err=>{
    
      this.toastr.errorToastr(err.error.replyMsg, 'Error');
    });
  }
  
  }  

}
